import axios from 'axios'

const API = '/bibliotecas'

const Conjuntos = {
    getDetailFolder(idFolder){
        return axios.get(`${API}/${idFolder}/carpeta`);
    },
    getFilesFolder(idFolder){
        return axios.get(`${API}/${idFolder}/archivos/lista`);
    },
    getFilesGroupedFolder(idFolder){
        return axios.get(`${API}/${idFolder}/archivos/lista-agrupados`);
    },
    postFile(payload){
        return axios.post(`${API}/archivos/guardar`, payload);
    },
    putFile(id, payload){
        return axios.put(`${API}/archivos/${id}/actualizar`, payload);
    },
    deleteFile(id){
        return axios.delete(`${API}/archivos/${id}/eliminar`);
    },
    getCarpetas(){
        return axios.get(`${API}/listar-carpetas`);
    },
    putMoveFile(id, payload){
        return axios.put(`${API}/archivos/${id}/mover`, payload);
    },
    dowloadFile(idFile){
        return axios.get(`${API}/archivos/${idFile}/download-file`);
    }
}

export default Conjuntos
