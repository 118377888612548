<template>
    <section class="px-4 pt-3">
        <cargando v-if="cargando" />
        <div class="row mx-0 mb-4 mt-2">
            <div class="col-auto px-0 text-black f-500 f-20">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                    <el-breadcrumb-item :to="{ name: 'biblioteca.carpetas' }">
                        Gestión Administrativa
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>{{ carpeta.nombre }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="btn-general ml-auto px-3 cr-pointer br-4" @click="goUploadFile">
                <p class="py-1">Subir archivo</p>
            </div>
            <div class="col-3">
                <el-input v-model="search" prefix-icon="icon-search f-16" size="small" placeholder="Buscar" class="w-100" />
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-auto text-black f-14">
                <i class="f-22" :class="`${iconFolder(carpeta.tipo)}`" />
                <span class="text-black f-14 ml-1">
                    {{ totalFiles }}  Archivos
                </span>
            </div>
            <div class="col-auto ml-auto cr-pointer">
                <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                    <div slot="content" class="tooltip-editar-residente">
                        <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="showComponent('todos')">
                            <p class="f-15">Ordenar por nombre</p>
                        </div>
                        <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="showComponent('agrupados')">
                            <p class="f-15">Ordenar por tipo de archivo</p>
                        </div>
                    </div>
                    <div class="d-middle-center br-4 f-15 cr-pointer">
                        <i class="icon-exchange gly-rotate-45 f-18" />
                    </div>
                </el-tooltip>
            </div>
        </div>
        <hr />
        <template v-if="showAll">
            <div class="row mx-0">
                <div v-for="(data, index) in files.filter(data => !search || data.nombre.toLowerCase().includes(search.toLowerCase()))" :key="index" class="col-auto position-relative pl-0 border mx-1 mt-2" style="width:180px">
                    <img v-if="typeImg.includes(data.extension.toLowerCase())" :src="data.archivo" class="galery-img mr-3 mb-2 mx-2 mt-2 obj-cover cr-pointer" @click="verAdjunto(data.archivo, data.extension)" />
                    <img v-else src="/img/no-imagen/Documento.svg" class="galery-img mr-3 mb-2 mx-2 mt-2 cr-pointer" @click="verAdjunto(data.archivo, data.extension)" />
                    <div class="position-absolute br-20 d-middle-center bg-white cr-pointer text-black shadow-14" style="height:24px;width:30px;top:12px;right:10px;">
                        <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                            <div slot="content" class="tooltip-editar-residente">
                                <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="dowloadFile(data.id)">
                                    <p class="f-15">Descargar</p>
                                </div>
                                <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="goEditFile(data.id, data.nombre)">
                                    <p class="f-15">Cambiar nombre</p>
                                </div>
                                <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="showDetail(data)">
                                    <p class="f-15">Detalle</p>
                                </div>
                                <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="moveFile(data.id)">
                                    <p class="f-15">Mover</p>
                                </div>
                                <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="confirmDeleteFile(data.id)">
                                    <p class="f-15">Eliminar</p>
                                </div>
                            </div>
                            <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer">
                                <i class="icon-vertical-points" />
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="col-auto mb-2">
                        <div class="d-flex">
                            <div class="d-middle-center my-auto">
                                <img :src="typeIcon(data.extension)" style="height:24px;width:20px" />
                            </div>
                            <div class="tres-puntos d-middle">
                                <p class="ml-1 f-12 tres-puntos">{{data.nombre}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="showGrouped">
            <div v-for="(item, idx) in filesGrouped" :key="idx">
                <div class="row mx-0 mt-4">
                    <div class="col-auto">
                        <p class="f-500">{{ item.extension.toUpperCase() }}</p>
                    </div>
                </div>
                <div class="row mx-0 mt-2">
                    <div v-for="(data, index) in item.items.filter(data => !search || data.nombre.toLowerCase().includes(search.toLowerCase()))" :key="index" class="col-auto position-relative pl-0 border mx-1 mt-2" style="width:180px">
                        <img v-if="typeImg.includes(data.extension.toLowerCase())" :src="data.archivo" class="galery-img mr-3 mb-2 mx-2 mt-2 obj-cover cr-pointer" @click="verAdjunto(data.archivo, data.extension)" />
                        <img v-else src="/img/no-imagen/Documento.svg" class="galery-img mr-3 mb-2 mx-2 mt-2 cr-pointer" @click="verAdjunto(data.archivo, data.extension)" />
                        <div class="position-absolute br-20 d-middle-center bg-white cr-pointer text-black shadow-14" style="height:24px;width:30px;top:12px;right:10px;">
                            <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                                <div slot="content" class="tooltip-editar-residente">
                                    <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="dowloadFile(data.id)">
                                        <p class="f-15">Descargar</p>
                                    </div>
                                    <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="goEditFile(data.id, data.nombre)">
                                        <p class="f-15">Cambiar nombre</p>
                                    </div>
                                    <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="showDetail(data)">
                                        <p class="f-15">Detalle</p>
                                    </div>
                                    <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="moveFile(data.id)">
                                        <p class="f-15">Mover</p>
                                    </div>
                                    <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="confirmDeleteFile(data.id)">
                                        <p class="f-15">Eliminar</p>
                                    </div>
                                </div>
                                <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer">
                                    <i class="icon-vertical-points" />
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto mb-2">
                            <div class="d-flex">
                                <div class="d-middle-center my-auto">
                                    <img :src="typeIcon(data.extension)" style="height:24px;width:20px" />
                                </div>
                                <div class="tres-puntos d-middle">
                                    <p class="ml-1 f-12 tres-puntos">{{data.nombre}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <!-- Partials -->
        <modal-subir-archivo ref="refModalSubirArchivo" @update="addNewFile" />
        <modal-editar-archivo ref="refModalEditarArchivo" @update="updateElement" />
        <modal-detalle-archivo ref="refModalDetalleArchivo" />
        <modal-mover-archivo ref="refModalMoverArchivo" @update="movedFile" />
        <modal-ver-imagen ref="refModalVerImagen" />
        <modal-ver-video ref="refModalVerVideo" />
        <modal ref="modalEliminarArchivo" titulo="Eliminar archivo" adicional="Eliminar" @adicional="deleteFile">
            <div class="row mx-0 justify-content-center">
                <div class="col-7">
                    <p>¿Desea eliminar este archivo?</p>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import Service from '~/services/biblioteca/archivos';

export default {
    components: {
        modalSubirArchivo: () => import('./partials/modalSubirArchivo'),
        modalEditarArchivo: () => import('./partials/modalEditarArchivo'),
        modalDetalleArchivo: () => import('./partials/modalDetalleArchivo'),
        modalMoverArchivo: () => import('./partials/modalMoverArchivo'),
        modalVerImagen: () => import('./partials/modalVerImagen'),
        modalVerVideo: () => import('./partials/modalVerVideo'),
    },

    data(){
        return {
            cargando: false,
            showAll: true,
            showGrouped: false,
            carpeta: {},
            files: [],
            filesGrouped: [],
            typeImg: [
                'png',
                'jpg',
                'jpeg',
                'svg',
                'webp',
                'gif',
                'tiff',
                'psd',
                'bmp',
                'eps'
            ],
            typeVideo: [
                'mp4',
                'avi',
                'mkv',
                'flv',
                'f4v',
                'swt',
                'mov',
                'wmv',
                'divx',
                'h.264',
                'xvid',
                'rm',
                'avchd',
                'webm',
                'html5',
                'mpeg-2'
            ],
            idFile: null,
            search: '',
        }
    },

    computed:{
        idCarpeta(){
            return Number(this.$route.params.idCarpeta);
        },

        totalFiles(){
            if(this.showAll){
                return this.files.length;
            }

            if(this.showGrouped){
                let total = 0;
                this.filesGrouped.map((e) => {
                    total = total + e.items.length;
                });
                return total;
            }
        }
    },

    mounted(){
        this.getDetailFolder();
    },

    methods:{
        async getDetailFolder(){
            try {
                this.cargando = true;
                const { data } = await Service.getDetailFolder(this.idCarpeta);
                this.carpeta = data.data;
                this.getFiles();
            } catch(e){
                this.errorCatch(e);
            } finally {
                this.cargando = false;
            }
        },

        async getFiles(){
            try {
                this.cargando = true;
                const { data } = await Service.getFilesFolder(this.idCarpeta);
                this.files = data.data;
            } catch(e){
                this.errorCatch(e);
            } finally {
                this.cargando = false;
            }
        },

        async getFilesGrouped(){
            try {
                this.cargando = true;
                const { data } = await Service.getFilesGroupedFolder(this.idCarpeta);
                this.filesGrouped = data.data;
            } catch(e){
                this.errorCatch(e);
            } finally {
                this.cargando = false;
            }
        },

        async showComponent(bandera){
            if(bandera === 'todos'){
                if(this.showAll)return;

                this.showGrouped = false;
                this.showAll = true;
                this.getFiles();
            }
            if(bandera === 'agrupados'){
                if(this.showGrouped)return;

                this.showAll = false;
                this.showGrouped = true;
                this.getFilesGrouped();
            }
        },

        async addNewFile(file){
            if(this.showAll){
                this.files.push(file);
            } else {
                this.getFilesGrouped();
            }
        },

        async updateElement(data){
            if(this.showAll){
                let index = this.files.findIndex((item) => item.id === data.id);
                if(index !== -1){
                    this.files[index].nombre = data.name;
                }
            }
            if(this.showGrouped){
                this.getFilesGrouped();
            }
        },

        goUploadFile(){
            this.$refs.refModalSubirArchivo.toggle();
        },

        goEditFile(idFile, name){
            this.$refs.refModalEditarArchivo.toggle(
                {
                    id: idFile,
                    name: name,
                }
            );
        },  

        confirmDeleteFile(id){
            this.idFile = id;
            this.$refs.modalEliminarArchivo.toggle();
        },

        async deleteFile(){
            try {
                if(!this.idFile)return;

                const {data} = await Service.deleteFile(this.idFile);
                if(data.success){
                    this.$notify({
                        title: 'Mensaje',
                        message: data.mensaje,
                        type: 'success'
              	    });
                    if(this.showAll){
                        this.removeFile(this.idFile);
                    } else{
                        this.getFilesGrouped();
                    }
                }
            } catch(e){
                this.errorCatch(e);
            } finally {
                this.$refs.modalEliminarArchivo.toggle();
            }
        },

        removeFile(idFile){
            let index = this.files.findIndex(item => item.id === idFile);
            if(index !== -1){
                this.files.splice(index, 1);
            }
            this.idFile = null;
        },

        movedFile(idFile){
            if(this.showAll){
                this.removeFile(idFile);
            } else{
                this.getFilesGrouped();
            }
        },

        showDetail(data){
            this.$refs.refModalDetalleArchivo.toggle(data);
        },

        async dowloadFile(idFile){
            try {
                const { data } = await Service.dowloadFile(idFile);
                window.open(data.download, '_self');
            } catch(e){
                this.errorCatch(e);
            }
        },

        moveFile(id){
            this.$refs.refModalMoverArchivo.toggle(id);
        },

        typeIcon(ext){
            if(this.typeImg.includes(ext)){
                return '/img/icons/Imagen.svg';
            }
            if(this.typeVideo.includes(ext)){
                return '/img/icons/video-1.svg';
            }
            if(ext.toLowerCase() === 'pdf'){
                return '/img/icons/pdf.svg';
            }
            return '/img/icons/otros-doc.svg';
        },

        iconFolder(tipo){
            switch (tipo){
            case 1:
                return 'icon-lock'  
            case 2:
                return 'icon-group-outline'
            case 3:
                return 'icon-account-plus'    
            }
        },

        verAdjunto(archivo, extension){
            if(this.typeImg.includes(extension)){
                this.$refs.refModalVerImagen.toggle(archivo);
                return;
            }

            if(this.typeVideo.includes(extension)){
                this.$refs.refModalVerVideo.toggle(archivo);
                return;
            }

            window.open(archivo, '_blank');
        },
    }
}
</script>
<style lang="scss" scoped>
.galery-img{
    width: 100%;
    height: 100px;
}
.gly-rotate-45 {
  transform: rotate(90deg);
}
</style>